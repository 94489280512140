import React from "react"
import "./gallery.css"
import Logo from "./logo"
import { useStaticQuery, graphql } from "gatsby"

const ImageGallery = () => {
  const data = useStaticQuery(graphql`
    query CloudinaryImage {
      allCloudinaryMedia {
        edges {
          node {
            secure_url
            bytes
          }
        }
      }
    }
  `)
  const clImages = data.allCloudinaryMedia.edges

  return (
    <div>
      <div className="image-grid">
        {clImages.map((image, index) => (
          <div className="image-item" key={`${index}-cl`}>
            {image.node.bytes > 0 ? (
              <img src={image.node.secure_url} alt={"🐶"} />
            ) : (
              <div className="flex justify-center items-center h-full">
                <Logo />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
export default ImageGallery
