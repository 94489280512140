import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageGallery from "../components/ImageGallery"

// Might need to add pagination

const GalleryPage = ({ data }) => {
  const title = data.allContentfulGalleryPage.nodes[0].title
  const desc = data.allContentfulGalleryPage.nodes[0].seoDescription

  return (
    <Layout>
      <div className="px-6">
        <SEO title={title} description={desc} />
        <h1>{title}</h1>
        <p>&nbsp;</p>
        <div className="mb-2">
          <ImageGallery />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulGalleryPage {
      nodes {
        seoDescription
        title
      }
    }
  }
`

export default GalleryPage
